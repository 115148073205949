import React from "react";
import "./App.scss";

function App() {
  const art = 
    [ '       ___           ___           ___           ___           ___           ___           ___      ',
      '      /__/\\         /__/\\         /  /\\         /  /\\         /__/\\         /__/|         /  /\\     ',
      '     |  |  \\        \\  \\ \\       /  /  \\       /  / /_        \\  \\ \\       |  | |        /  /  \\    ',
      '     |  | | \\        \\  \\ \\     /  / /\\ \\     /  / / /\\        \\  \\ \\      |  | |       /  / /\\ \\   ',
      '   __|__| |\\ \\   ___  \\  \\ \\   /  / /~/ /    /  / / / /_   _____\\__\\ \\   __|  | |      /  / /  \\ \\  ',
      '  /__/    | \\ \\ /__/\\  \\__\\ \\ /__/ / / /___ /__/ / / / /\\ /__/        \\ /__/\\_| |____ /__/ / \\__\\ \\ ',
      '  \\  \\ \\~~\\__\\/ \\  \\ \\ /  / / \\  \\ \\/     / \\  \\ \\/ / / / \\  \\  ______/ \\  \\ \\/     / \\  \\ \\ /  / / ',
      '   \\  \\ \\        \\  \\ \\  / /   \\  \\  /~~~~   \\  \\  / / /   \\  \\ \\        \\  \\  /~~~~   \\  \\ \\  / /  ',
      '    \\  \\ \\        \\  \\ \\/ /     \\  \\ \\        \\  \\ \\/ /     \\  \\ \\        \\  \\ \\        \\  \\ \\/ /   ',
      '     \\  \\ \\        \\  \\  /       \\  \\ \\        \\  \\  /       \\  \\ \\        \\  \\ \\        \\  \\  /    ',
      '      \\__\\/         \\__\\/         \\__\\/         \\__\\/         \\__\\/         \\__\\/         \\__\\/     ',]


  return (
    <div className="main">
      {art.map((line, i) => {
       return <pre key={i}>{line}</pre>
      })}
    </div>
  );
}

export default App;
